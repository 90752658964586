import React, { lazy } from 'react'
import { RouteObject } from 'react-router-dom'
const UserListing = lazy(() => import('./UserListing'))
const EditUser = lazy(() => import('./UserEditForm'))
const AddUser = lazy(() => import('./UserAddForm'))

export const USER_PATH = {
  LISTING: '/user/user-list',
  USER_FORM: '/user/edit-user/:userID',
  USER_ADD_FORM: '/user/add-user',
}

const UserRoutes: RouteObject[] = [
  {
    path: '',
    element: <UserListing />,
  },
  {
    path: 'user-list',
    element: <UserListing />,
  },
  {
    path: 'edit-user/:userID',
    element: <EditUser />,
  },
  {
    path: 'add-user',
    element: <AddUser />,
  },
]
export default UserRoutes
