import {
  DesktopHeader,
  Drawer,
  layoutClasses,
  LayoutRoot,
  ModuleSection,
  ProfileMenuItem,
  ProfileSection,
  Spinner,
} from '@ifca-ui/core'
import {
  Home as HomeIcon,
  Logout as LogoutIcon,
  Profile as ProfileIcon,
} from '@ifca-ui/icons/dist/index.esm'
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive'
import PeopleIcon from '@mui/icons-material/People'
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  useMediaQuery,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import SideMenuBg from 'assets/images/product/sidemenu-background.svg'
import clsx from 'clsx'
import HeaderX from 'components/HeaderX/HeaderX'
import AppContext, { AppContextProps } from 'containers/context/Context'
import { ACCT_PATH } from 'containers/modules/Account/Routes'
import {
  useGetSolicitorAccountNameLazyQuery,
  useProfileLazyQuery,
} from 'generated/graphql'
import { navigationService } from 'navigationService'
import * as React from 'react'
import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom'
import {
  getEmail,
  getName,
  getSolicitorDetail,
  getUrl,
  reset,
  setEmail,
  setName,
  setSolicitorDetail,
  setUrl,
} from 'userDetail'
interface Modules {
  name?: string
  path?: string
  icon?: any
  iconActived?: any
}
interface MainLayoutProps {
  children?: React.ReactNode
  window?: () => Window
}
const MainLayout: React.FC<MainLayoutProps> = ({ children, window }) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const nav = new navigationService()
  // const [name, setName] = React.useState('')
  // const [email, setEmail] = React.useState('')
  // const [url, setUrl] = React.useState('')
  const [solicitor, setSolicitor] = React.useState('')
  const { rootState, rootDispatch } =
    React.useContext<AppContextProps>(AppContext)
  const ModulesList: Modules[] = [
    {
      name: 'Home',
      path: '/home',
      icon: <HomeIcon className="svg-icon" />,
      iconActived: <HomeIcon className="svg-icon" />,
    },
    {
      name: 'User',
      path: '/user/user-list',
      icon: <PeopleIcon color="primary" />,
      iconActived: <PeopleIcon className="svg-icon" color="primary" />,
    },
    {
      name: 'Notification',
      path: '/permission',
      icon: <NotificationsActiveIcon color="primary" />,
      iconActived: (
        <NotificationsActiveIcon className="svg-icon" color="primary" />
      ),
    },
    {
      name: 'Log Out',
      path: '/login',
      icon: <LogoutIcon className="svg-icon" />,
      iconActived: <LogoutIcon className="svg-icon" />,
    },
  ]
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const theme = useTheme()
  const container =
    window !== undefined ? () => window().document.body : undefined
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })
  const profileMenu = (
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <ProfileMenuItem
        onClick={() => {
          if (!isDesktop) {
            handleDrawerOpen()
          }
          navigate(ACCT_PATH.PROFILE)
          handleClose()
        }}
      >
        <ProfileIcon width="18px" height="18px" />
        Profile
      </ProfileMenuItem>
      <ProfileMenuItem onClick={() => clickLogout()}>
        <LogoutIcon width="18px" height="18px" />
        Logout
      </ProfileMenuItem>
    </Menu>
  )
  const handleDrawerOpen = () =>
    rootDispatch({ type: 'isDrawerOpen', payload: !rootState.isDrawerOpen })
  React.useEffect(() => {
    if (isDesktop && !rootState.isDrawerOpen) {
      document?.getElementById('main-header')?.classList.add('full-width')
      document
        ?.getElementById('subheader-container')
        ?.classList.add('full-width')
      document?.getElementById('footer')?.classList.add('full-width')
    } else {
      document?.getElementById('main-header')?.classList.remove('full-width')
      document
        ?.getElementById('subheader-container')
        ?.classList.remove('full-width')
      document?.getElementById('footer')?.classList.remove('full-width')
    }
  }, [rootState.isDrawerOpen, isDesktop, pathname])

  const [getSolicitorName, { data }] = useGetSolicitorAccountNameLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      if (data?.getSolicitorAccountName) {
        setSolicitorDetail(data?.getSolicitorAccountName)
      }
    },
    onError: error => {
      return error.graphQLErrors.map(({ message }) => {
        rootDispatch({
          type: 'snackBar',
          payload: {
            open: true,
            message: message,
          },
        })
      })
    },
  })

  const [
    getProfileName,
    {
      data: { profile: profile } = {
        profile: null,
      },
    },
  ] = useProfileLazyQuery({
    fetchPolicy: 'no-cache',
    onError: error => {
      return error.graphQLErrors.map(({ message }) => {
        rootDispatch({
          type: 'snackBar',
          payload: {
            open: true,
            message: message,
          },
        })
      })
    },
    onCompleted: () => {
      setName(profile?.name)
      setEmail(profile?.email)
      profile?.photoUrl !== null ? setUrl(profile?.photoUrl) : setUrl(' ')
    },
  })

  React.useEffect(() => {
    if (getName() === '' || getUrl() === '' || getEmail() === '') {
      getProfileName()
    }
  }, [getName() === '', getUrl() === '', getEmail() === ''])

  React.useEffect(() => {
    if (getSolicitorDetail() === '') {
      getSolicitorName()
    }
  }, [getSolicitorDetail() === ''])

  function handle(ob) {
    if (!isDesktop) {
      handleDrawerOpen()
    }
    if (ob.currentTarget.id === 'Home') {
      nav.backHome()
    }
    if (ob.currentTarget.id === 'Log Out') {
      localStorage.clear()
      reset()
      sessionStorage.clear()
      nav.clearArray()
      navigate('/login')
    }
    if (
      ob.currentTarget.id === 'User' ||
      ob.currentTarget.id === 'Notification'
    ) {
      nav.clickSideBar(ob.currentTarget.id)
    }
  }

  function clickLogout() {
    localStorage.clear()
    sessionStorage.clear()
    nav.clearArray()
    reset()
    navigate('/login')
  }

  return (
    <LayoutRoot component="div" className={layoutClasses.root} display="flex">
      {isDesktop ? (
        <DesktopHeader
          title={getSolicitorDetail()}
          leftIcon={{
            props: {
              onClick: () => handleDrawerOpen(),
            },
          }}
        />
      ) : null}

      <HeaderX isDesktop={isDesktop} />
      <Drawer
        container={container}
        onClose={handleDrawerOpen}
        open={isDesktop ? rootState.isDrawerOpen : !rootState.isDrawerOpen}
        variant={isDesktop ? 'persistent' : 'temporary'}
      >
        <ProfileSection
          drawerImage={SideMenuBg}
          avatar={{
            src: getUrl(),
          }}
          profile={{
            title: `${getName()}`,
            subTitle: `${getEmail()}`,
          }}
          dropDownIcon={{
            onClick: e => handleClick(e),
          }}
        />
        <ModuleSection as="div">
          <List disablePadding>
            {ModulesList.map((module, index) => (
              <ListItem
                id={module.name}
                key={index}
                dense
                divider
                component={NavLink}
                to={module.path}
                onClick={e => handle(e)}
              >
                <ListItemIcon>
                  {pathname !== module.path && module.icon}
                  {pathname === module.path && module.iconActived}
                </ListItemIcon>
                <ListItemText
                  primary={module.name}
                  primaryTypographyProps={{
                    variant: 'inherit',
                  }}
                />
              </ListItem>
            ))}
          </List>
        </ModuleSection>
      </Drawer>
      {profileMenu}
      <Box
        component="main"
        className={clsx(layoutClasses.mainContent, {
          [layoutClasses.contentShift]: rootState.isDrawerOpen,
        })}
      >
        <React.Suspense fallback={<Spinner />}>
          <div style={{ height: `${rootState.headerHeight}px` }} />
          <Outlet />
        </React.Suspense>
      </Box>
    </LayoutRoot>
  )
}
export default MainLayout
