import { ApolloProvider } from '@apollo/client'
import { Snackbar, Spinner, Theme } from '@ifca-ui/core'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { Button, CssBaseline } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import AppContext from '../context/Context'
import { RootInitialState, RootReducer } from 'containers/context/Reducer'
//  import { createBrowserHistory } from 'history'
import { client } from 'PropertyClient'
import { useEffect } from 'react'
import * as React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { loadingObservable } from 'containers/helper/loadingHelper'
import { snackBarObservable } from '../helper/snackBarSubjectHelper'
import 'assets/styles/app.scss'
import { MainRoutes } from 'containers/routes/RootRoutes'
import NavigationProvider from './NavigationProvider'
import { LocationRoute } from 'containers/routes/LocationRoute'
import '@ifca-ui/core/src/styles/style.scss'

export default function App() {
  const [rootState, rootDispatch] = React.useReducer(
    RootReducer,
    RootInitialState
  )

  const handleClose = (
    event?: Event | React.SyntheticEvent,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return
    }

    rootDispatch({
      type: 'snackBar',
      payload: {
        open: false,
        message: '',
      },
    })
  }

  useEffect(() => {
    loadingObservable().subscribe(x => {
      rootDispatch({
        type: 'loading',
        payload: x,
      })
    })

    snackBarObservable().subscribe(x => {
      if (x) {
        rootDispatch({
          type: 'snackBar',
          payload: {
            open: true,
            message: x,
          },
        })
      }
    })
  }, [])

  return (
    <ThemeProvider theme={Theme}>
      <ApolloProvider client={client}>
        <CssBaseline />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <AppContext.Provider value={{ rootState, rootDispatch }}>
            {rootState?.loading ? <Spinner /> : null}
            <BrowserRouter>
              <NavigationProvider />
              <LocationRoute />
              <MainRoutes />
            </BrowserRouter>
            <Snackbar
              open={rootState.snackBar?.open}
              autoHideDuration={2000}
              onClose={handleClose}
              message={rootState.snackBar?.message}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              action={
                <Button color="secondary" size="small" onClick={handleClose}>
                  DISMISS
                </Button>
              }
            />
          </AppContext.Provider>
        </LocalizationProvider>
      </ApolloProvider>
    </ThemeProvider>
  )
}
